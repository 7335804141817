import React from "react"
import { graphql } from "gatsby"

import { Seo as SEO } from '../components/Seo'
import Layout from '../components/layout/Layout'
const PageBanner = React.lazy(() => import("../components/common/PageBanner"))
const ContactSection = React.lazy(() => import("../components/contact/ContactSection"))
const ContactForm = React.lazy(() => import("../components/contact/ContactForm"))

const ContactPage = ({data}) => {
    const links = [
      {label: "Home", url: "/"},
      {label: "Contact Us", url: "/contact"},
    ]
    return(
        <Layout pathname="/contact">
          <PageBanner title={"Contact Us"} links={links} bannerImage={data.banner.gatsbyImageData}/>
          <ContactSection/>
          <ContactForm/>
        </Layout>
    )
}

export default ContactPage

export const Head = () => {
    return (
      <SEO
        title={'ReGeniC | Contact Us'}
        description={
          'Get in touch with ReGeniC and explore partnership opportunities. Contact our team for inquiries, collaborations, or to learn more about our biopharmaceutical solutions for a healthier nation.'
        }
      />
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "contact", "validation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    banner: contentfulAsset(title: {eq: "Contact Us Banner"}) {
        gatsbyImageData
    }
  }
`